/*! CSS Used from: http://127.0.0.1:5500/front-end/include/css/bootstrap.min.css */
*,
::after,
::before {
  box-sizing: border-box;
}
h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
img {
  vertical-align: middle;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button {
  text-transform: none;
}
[type="button"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
.d-flex {
  display: flex !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.align-items-center {
  align-items: center !important;
}
/*! CSS Used from: http://127.0.0.1:5500/front-end/include/css/style.css */
* {
  margin: 0;
  padding: 0;
}
h4,
p {
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
}
*:focus {
  outline: 0 !important;
}
button {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  outline: none;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}
img {
  max-width: 100%;
  border: 0;
  height: auto;
}
h4 {
  margin: 0px;
  padding: 0px;
  margin-bottom: 24px;
  line-height: 1.2;
  font-weight: 700;
  color: inherit;
}
h4 {
  font-size: 30px;
}
p {
  margin: 0px;
  padding: 0px;
  margin-bottom: 24px;
}
p:last-child {
  margin-bottom: 0;
}
.gfr-btn-row-text h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}
.ticket-user-list {
  margin-top: 20px;
  width: calc(100% + 30px);
  margin-left: -15px;
}
.ticket-user-item {
  width: auto;
  padding: 12px 15px;
}
.ticket-user-item:not(:last-child):before {
  content: "";
  width: 1px;
  height: calc(100% - 35px);
  background: #000000;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.border-radius-50 {
  border-radius: 50%;
}
.ticket-user-title {
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: #000000;
}
.ticket-user-box {
  color: #000000;
  font-size: 8px;
  font-weight: 500;
  line-height: 14px;
}
.ticket-user-box h6 {
  font-size: 20px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 0;
}
.ticket-user-para {
  color: #000000;
  font-size: 9px;
  font-weight: 500;
  line-height: 1;
  margin-top: 5px;
}
.ticket-user-img {
  width: 32px;
  height: 32px;
  overflow: hidden;
  margin-right: 10px;
}
.ticket-user-text {
  width: calc(100% - 42px);
}
.gfr-btn-row-text {
  font-size: 12px;
  line-height: 1.3;
  font-weight: 400;
  text-transform: uppercase;
}
.gfr-btn-row {
  color: white;
  border-radius: 18.955px;
  background: linear-gradient(4deg, #fb7411 8.33%, #f0982c 100%);
  backdrop-filter: blur(13.319828033447266px);
  padding: 14px 23px;
}
.gfr-btn-row.has-green-gradient {
  background: linear-gradient(158deg, #62e724 0%, #3da50c 100%);
}
.gfr-btn-row.has-yellow-gradient {
  background: linear-gradient(4deg, #ffd566 8.33%, #efba2c 100%);
}
.gfr-btn-row-icon {
  width: 24px;
  margin-right: 12px;
}
.gfr-btn-row-btn {
  width: 122px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.gfr-btn-row-text {
  width: calc(100% - 36px);
}
.btn-row-btn {
  border: 0;
  padding: 10px 14px;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.15);
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5;
}
.btn-row-btn .btn-row-nfo-icon {
  width: 17px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}
.gfr-btn-row-left {
  width: calc(100% - 130px);
}
.gfr-lower {
  margin-bottom: 20px;
}
@media (max-width: 991.98px) {
  .btn-row-btn span {
    display: none;
  }
  .btn-row-btn {
    padding: 0;
    font-size: 10px;
    background: transparent;
  }
  .gfr-btn-row-text h4 {
    font-size: 18px;
  }
  .gfr-btn-row-btn {
    width: 27px;
    height: 27px;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
  .btn-row-btn .btn-row-nfo-icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
  }
  .gfr-btn-row {
    padding: 21px 35px 21px 15px;
  }
}
@media (max-width: 575.98px) {
  .gfr-btn-row.d-flex {
    flex-wrap: wrap;
    flex-flow: column;
  }
  .gfr-btn-row-left {
    width: 100%;
  }
  .gfr-btn-row-text h4 {
    font-size: 14px;
  }
}
@media (max-width: 389.98px) {
  .gfr-btn-row-text h4 {
    font-size: 14px;
  }
  .gfr-btn-row-text {
    font-size: 10px;
  }
}
/*! CSS Used from: Embedded ; media=all */
@media all {
  .fa-spin {
    -webkit-animation: fa-spin 2s linear infinite;
    animation: fa-spin 2s linear infinite;
  }
}
/*! CSS Used keyframes */
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
