.flyer-table > td,
.vertical-middle {
  vertical-align: middle !important;
}
.Toastify__toast-container {
  z-index: 99999999999 !important;
  width: auto !important;
  max-width: 600px !important;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 0px !important;
}
ul.help-listing li:not(:first-child) {
  margin: 15px 0;
}
.custom-date-picker-container {
  z-index: 99999;
}
.arcade {
  background: #eacfff;
  color: #9411fb;
}
.loot {
  background: #ffe8cc;
  color: #fb7411;
}
.tickets {
  background: #e1ffd4;
  color: #3da50c;
}
.small-date {
  padding-top: 10px !important;
}
.delete-icon {
  z-index: unset;
}
.eg-grid.gw-list li {
  width: 25%;
}
.ict-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-dark);
  /* margin-bottom: 0; */
}
.table.trs-gray thead {
  color: #000;
  background: #e6e6e6;
}
#header .navbar-brand {
  width: 200px;
}
.custom-date-range-picker {
  position: absolute;
  left: -530px;
  top: 30px;
  background-color: white;
  border: 1px solid #c0b7e3;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 99999;
}
.eg-sub-grid li {
  width: 50%;
}
.eg-sub-grid-wrapper {
  margin-top: 27px !important;
}
.date-picker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}
.retention-tabs-wrapper .nav {
  background: #f6f8f9;
}

.retention-tabs-wrapper .nav .nav-item a.nav-link.active {
  background-color: #e46222;
  color: white;
}

.retention-tabs-wrapper .nav .nav-item a.nav-link {
  color: black;
}
.custom-date-range-picker .rdrDefinedRangesWrapper {
  /* display: none; */
}
.custom-date-range-picker .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.custom-date-range-picker .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.custom-date-range-picker .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span {
  color: white !important;
  font-weight: 500;
}
.custom-date-range-picker .rdrDayToday .rdrDayNumber span:after {
  background: #e46223;
}
.custom-date-range-picker .rdrDateDisplayItemActive input,
.custom-date-range-picker .rdrDateDisplayItem input {
  color: black;
}
.sidebar-submenu-item:hover > a .angle-arrow,
.sidebar-submenu-item > a .angle-arrow {
  opacity: 1;
  visibility: visible;
}
.gfr-btn-row.has-purple-gradient {
  background: linear-gradient(158deg, #a675e1 0%, #530da6 100%);
}
ul.help-listing li span {
  margin-right: 15px;
}
.tooltip-inner-ca {
  width: 20rem;
  max-width: 20rem;
}
.keyword-w-50 {
  width: 50% !important;
}
.max-w-30 {
  max-width: 60px;
}
.crm-wrapper span {
  padding: 14px !important;
}
.offcanvas-body-2 {
  padding: 1rem 0 !important;
}
.kt-upload-outer-small {
  width: 40px;
  height: 40px;
}
.yellow-bg {
  background: #ffcc00;
}
.round-title {
  font-weight: 800;
  margin-bottom: 10px;
  padding: 25px;
  border-bottom: 1px solid #ebebeb;
}
.p-25 {
  padding: 25px;
}
.cbw-card-item {
  width: 50%;
}
.width-15px {
  width: 15px;
}
.load-more img {
  width: 25px;
}
.td-list-img {
  border-radius: 3px;
}
.w-16 {
  width: 16.33% !important;
}
.keyword-w-34 {
  width: 34% !important;
}
.fwc-body tbody {
  border-top: unset !important;
}
.upload-img-outer .drag-file-input {
  cursor: pointer;
}
.pointer-default {
  cursor: default !important;
}
.has-circle-radio .form-check-input {
  cursor: pointer;
}
.input-wrapper-loader {
  display: flex;
  position: relative;
  align-items: center;
}
.btn-loading img {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.input-wrapper-loader img {
  width: 20px;
  position: absolute;
  right: 20px;
}
.btn-default,
.btn-default:focus {
  box-shadow: none;
}
.form-control.error {
  border-bottom: 1px solid #f44336;
}
.camelCase {
  text-transform: capitalize;
}
.border-error {
  border-width: 1px !important;
  border-color: #f44336 !important;
}
.custom-dropdown-toggle {
  background-color: unset !important;
  border: unset !important;
  min-width: unset !important;
  color: #000 !important;
  padding: 0;
}
.custom-dropdown-toggle2 {
  background-color: unset !important;
  border: unset !important;
  min-width: unset !important;
  padding: 0;
}
.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ellipsis {
  display: block;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pointer {
  cursor: pointer;
}
.one-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.large-table.table tr td:first-child,
.large-table.table tr td:nth-child(2) {
  text-align: left !important;
}
.para-line {
  white-space: pre-line;
}
.mcw-header-top p {
  color: rgba(0, 0, 0, 0.5) !important;
  display: block;
  padding-top: 5px !important;
  padding-left: 14px;
}
input.custom-form-control-text {
  width: 100%;
  border: 0;
  resize: none;
  line-height: 1.5;
}
input.custom-form-control-text::placeholder {
  color: #000;
  opacity: 1;
}
.custom-img-radius-5 img {
  border-radius: 5px !important;
}
.mcw-header .short-h1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40rem;
}

.changed-row {
  background-color: #fae0d3;
}
.gcdr-details-item .nft-address {
}
.formatted-text a {
  display: inline;
}
.nft-seller-img img {
  border-radius: 50%;
  width: 70px;
}
.endText span {
  width: 58px;
}
.mr-inner-content .form-group .forgot-psw a {
  padding-right: 0;
}
.endText {
  display: flex;
}
.upperCase {
  text-transform: uppercase;
}

.custom-tooltip {
  background-color: #1c1d23 !important;
  opacity: 1 !important;
  border-radius: 4px !important;
  color: white !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}
.gcdr-item h6 {
  font-size: 18px;
}
.colckdiv-item:first-child > span,
.colckdiv-item:first-child .smalltext {
  color: #171717 !important;
}
.show-dollar-value {
  background-color: #f5f5f5;
  color: var(--color-black);
  height: 40px;
  border: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #000000;
  padding: 10px 45px 10px 36px;
  border-radius: 6px !important;
}
.gcdr-heading {
  margin-bottom: 0px;
}
.gcdr-widget-wrapper {
  margin-top: 40px;
}
.profile-initials {
  background: white;
  width: 40px;
  height: 40px;
}
.profile-initials p {
  color: black;
  opacity: 0.6;
}
.gcdr-image > video,
.gcdr-image > iframe,
.kt-upload-img > video,
.kt-upload-img > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.mb-20 {
  margin-bottom: 20px;
}

.gc-header-left.width90 {
  width: 90%;
}
.gc-header-left.width50 {
  width: 50%;
}
.width150 {
  width: 150%;
}
.red-bg {
  background-color: #ce2e2e !important;
}
.ust-tag .balance1 {
  background-color: #8309ff;
}
.ust-tag .balance2 {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #0ca577;
}
.opacity-5 {
  opacity: 0.5;
}
.opacity-2 {
  opacity: 0.2;
}
.margin-top-30 {
  margin-top: 30px;
}
.credit-icon {
  width: 13px;
}

.icon-input span {
  left: unset;
  right: 8px;
}
.icon-input input {
  padding-left: 14px !important;
}

.review-switch .form-check-input {
  height: 24px !important;
  width: 55px !important ;
}
.review-switch .form-check-input:focus {
  border: 2px solid #e4e4e4 !important;
  box-shadow: none !important;
  /* background-image: ; */
}

.review-switch.form-switch .form-check-input:checked {
  background-color: #000 !important;
  border: none !important;
}

.review-switch.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e") !important;
}
.review-switch.form-switch .form-check-input:focus:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

.question-filter-mode-list li {
  width: 25% !important;
}

/* Date Picker */

.MuiInputBase-root {
  background-color: #f5f5f5 !important;
  height: 40px !important;
  border: 0 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 150% !important;
  letter-spacing: 0.01em !important;
  color: #000000 !important;
  border-radius: 6px !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiButtonBase-root.MuiPickersDay-root.Mui-selected,
.PrivatePickersYear-yearButton.Mui-selected,
.css-118whkv,
.css-2ujp1m {
  background: #1c1d23 !important;
  background-color: #1c1d23 !important;
  color: white !important;
}
.retention-wrapper .mcw-header {
  color: white;
}
.css-fhpqww .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium .MuiTypography-root.MuiTypography-caption {
  line-height: 1.66 !important;
}
.MuiPaper-root.MuiPaper-elevation {
  box-shadow: 0px 2px 20px -2px rgb(0 0 0 / 10%) !important;
  padding: 12px !important;
  border-radius: 4px !important;
}
.MuiPickersDay-root,
.MuiCalendarPicker-root .PrivatePickersFadeTransitionGroup-root,
.PrivatePickersYear-yearButton {
  color: #1b1b1b !important;
  font-family: proxima-nova, sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16px !important;
}
.MuiPickersDay-root.Mui-disabled {
  font-weight: 500 !important;
  color: #c6c6c6 !important;
}
.css-12ha4i7,
.css-7lip4c,
.css-a1rc6s {
  background-color: #1b1b1b !important;
}
.css-118whkv,
.css-2ujp1m {
  border-color: #1b1b1b !important;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.datepicker.error.MuiTextField-root {
  border-bottom: 1px solid #ce2e2e !important;
}
a.disabled {
  pointer-events: none;
}
.custom-dropdown .dropdown-menu {
  margin-top: 10px;
}
.ut-item-box-inner .image-attribute-details-box.small-attribute strong {
  font-size: 14px;
  width: 100%;
}
.cancelled {
  background-color: #ba0303;
}
.alert.alert-red {
  background-color: #ba0303;
  color: white;
  box-shadow: 0px 2px 23px rgba(0, 0, 0, 0.197552);
}
.analytics-wrapper .gw-box em {
  color: #000;
}
.alert .alert-heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  color: white;
}
.alert .alert-text {
  margin-top: 10px;
}
.lock-screen {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: black;
  opacity: 0.2;
  z-index: 9999999;
}
.alert-heading.alert-heading-dark {
  color: #000 !important;
}
.kt-upload-outer-banner {
  width: 407px;
}
.kt-upload-outer-banner .kt-upload-img {
  padding-bottom: 38%;
}
.custom-label {
  margin: 8px 0 0 0;
  position: absolute;
  bottom: -22px;
}
.custom-label span {
  color: #939393;
}
.link-game {
  margin-left: 0 !important;
}
.new-player-list {
  width: calc(100% + 60px);
  margin-left: -30px;
  list-style: none;
  padding: 0;
}
.new-player-list .new-player-item {
  width: 33.33%;
  padding: 30px;
}
.selected-player.new-player-box {
  border-color: #449c28 !important;
}
.new-player-box {
  border-radius: 15px;
}
.new-player-img-box {
  height: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  border: 2px solid #fff;
}
.npi-icon-outer {
  width: 48px;
  height: 48px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -24px;
  padding: 3px;
}
.img-card-wrapper {
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
}
.img-card {
  height: 0;
  padding-bottom: 57%;
  width: 100%;
  position: relative;
}
.new-player-img-box img,
.new-player-img-box {
  border-radius: 10px;
}
.img-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 0;
  left: 0;
  top: 0;
}
.inner-spin-border {
  width: 100%;
  height: 50%;
  background-color: #fff;
  border-radius: 50px 50px 0 0;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: -1;
}
.selected-player .npi-icon-box {
  background: #449c28;
}

.npi-icon-box {
  width: 100%;
  height: 100%;
  background: #c32525;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.disable-player .new-player-img-box img {
  filter: grayscale(1) !important;
  opacity: 0.5 !important;
}
.dark-bg:not(.loading) .new-player-box {
  background: #fff;
}
.new-player-box.disable-player {
  border-color: #929292 !important;
}
.disable-player .npi-icon-box {
  background: #929292 !important;
}
.margin-right-2 {
  margin-right: 10px !important;
}
.game-details-value {
  font-weight: 400;
}
.e404-image {
  object-fit: contain !important;
}
.subTitle-line2 {
  opacity: 0.5;
}
.right-icons-container:hover .copy-data-icons {
  opacity: 1;
}
.copy-data-icons {
  opacity: 0;
  width: 40px;
  text-align: center;
  background-image: linear-gradient(to left, #ffffff, #ffffffeb);
}
.copy-data-icons img {
  width: 14px;
}
.round-img {
  width: 30px !important;
  height: 30px !important;
}
.round-img img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.m-right-2 {
  margin-right: 10px !important;
}
.m-top-2 {
  margin-top: 10px !important;
}
th,
td {
  vertical-align: top !important;
}
.m-right-2 {
  margin-right: 10px;
}
.large-table.table tr td:first-child,
.large-table.table tr td:nth-child(2) {
  vertical-align: top !important;
}
.table.custom-table-striped.cts-gray thead {
  color: #000;
  background: #fff2eb;
}
.table.custom-table-striped.cts-gray tbody tr {
  background: rgb(238 240 243 / 30%);
}
.custom-offcanvas-pannel2 {
  width: 1200px;
}
.custom-offcanvas-pannel3 {
  width: 1400px;
}
.custom-offcanvas-pannel .offcanvas-header.offCanvas-header-2 {
  height: 97px;
}
.custom-offcanvas-pannel .offcanvas-header.offCanvas-header-3 {
  height: 154px;
}
.visibility-hidden-imp {
  visibility: hidden !important;
}
.z-index-1000 {
  z-index: 1000;
}
.z-index-high {
  z-index: 9999999;
}
.z-index-highest {
  z-index: 99999999 !important;
}
.underlined {
  text-decoration: underline;
}
.font-16 {
  font-size: 16px;
  font-weight: 600;
}
.font-12 {
  font-size: 12px;
}
.pointer-none {
  pointer-events: none;
}
.orange-text:hover {
  text-decoration: underline;
}
@media (max-width: 1670px) {
  .grid-card-list .grid-card-item {
    width: 33.33%;
  }
}

@media (max-width: 1300px) {
  .grid-card-list .grid-card-item {
    width: 50%;
  }
}
.review-switch2 {
  position: relative;
}
.review-switch2 .form-check-input {
  height: 30px !important;
  width: 85px !important;
}
.toggle-is-off label {
  position: absolute;
  left: 32px;
  top: 8px;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
}
.toggle-is-on label {
  position: absolute;
  top: 8px;
  font-size: 11px;
  left: 10px;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
.enlarge-icon {
  background: #1c1d23db;
  width: 29px;
  height: 29px;
  position: absolute;
  left: 6px;
  top: 7px;
  z-index: 2;
  border-radius: 6px;
}
.enlarge-icon + .enlarge-icon {
  left: 41px;
}
.image-show img {
  min-height: 400px;
}
.green-bg {
  background: linear-gradient(0deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0.1)), #0ca577 !important;
}
.green {
  color: green;
}
.red {
  color: red;
}
.green-bg p {
  font-weight: 600 !important;
}
.full-screen-modal {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.largeCloseIcon img {
  width: 19px;
}
.no-position img {
  position: unset !important;
}
.credit-icon-new img {
  width: 15px;
  margin-bottom: 5px;
}
.margin-left-65 {
  margin-left: 65px;
}
/* .filter-tag-item {
  margin-bottom: 10px;
} */
.disable-hover:hover {
  cursor: default;
  transform: unset;
}
.half-width-modal {
  width: 1000px;
}
.mt-30 {
  margin-top: 30px;
}
.ml-40 {
  margin-left: 40px;
}
.flyer-table.table tr td:first-child,
.flyer-table.table tr td:last-child {
  vertical-align: middle !important;
}
a.disable {
  pointer-events: none;
}
.ml-10 {
  margin-left: 10px;
}
.btn.orange-hover:hover {
  background-color: #e46223;
  border-color: #e46223;
}
.new-player-button {
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -32px;
  padding: 0;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-player-button.vbl-low {
  bottom: -60px;
}
.new-player-btn.btn-sm {
  padding: 8px 15px;
  min-width: 195px !important;
  width: 100%;
}
.new-player-btn {
  font-weight: 800;
  font-size: 15px;
  line-height: 24px;
  min-width: 128px;
  border-radius: 50px;
  padding: 10px 15px;
  color: #fff;
  border: 4px solid #f8f8f8;
}
.correct-btn .new-player-btn {
  background: linear-gradient(5.58deg, #fb7411 99.75%, #f0982c 3.97%) !important;
  box-shadow: none !important;
}
.wrong-btn .new-player-btn {
  background: #c32525 !important;
}
.disable-answer-btn .new-player-btn {
  background: #929292 !important;
}
.ust-tag span {
  padding: 6px 14px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
  line-height: 17.05px;
}
.ust-tag span.suspended {
  color: #f10d0d;
  background-color: #f10d0d1a;
}
.ust-tag span.inactive {
  color: #ffcc00;
  background-color: #ffcc001a;
}
.ust-tag span.active {
  color: #38ae9b;
  background-color: #38ae9b1a;
}
.has-error .invalid-feedback {
  display: block;
  color: #ff0000;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}
.kt-upload-outer-1 {
  width: 62px;
  height: 62px;
  margin: 5px;
}
.kt-upload-img-1 img {
  border-radius: 6px;
}
textarea.form-control {
  height: 160px;
}

.kt-upload-outer-banner-2-h-100 .kt-upload-img img {
  height: 100%;
  object-fit: cover;
}
.kt-upload-outer-banner-2 {
  width: 150px;
  height: 250px;
}
.kt-upload-outer-banner-2 .kt-upload-img {
  padding-bottom: 170%;
}
.kt-upload-outer-banner-2-h-100 .kt-upload-img img {
  height: 100%;
  object-fit: cover;
}
.kt-file-input input#formFile2,
.kt-file-input input#formFile3,
.kt-file-input input#formFile4 {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.tooltip-inner-reason p {
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  text-align: left;
}
.tooltip-inner-reason h4 {
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  opacity: 0.7;
  margin-bottom: 6px;
}
.btn-outline-dark {
  color: #1c1d23;
  border: solid 2px #1c1d23;
}
.mis-flyer-img-outer {
  width: 40px;
  height: 40px;
}
.mis-flyer-img-outer img.mis-flyer-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modal-backdrop {
  transition: opacity 0.15s linear;
}
.margin-right-5 {
  margin-right: 40px;
}
.filter-link-box.filter-link-box-comment {
  display: unset;
  margin-right: 25px;
}
.offcanvas-header-bottom h5 {
  margin-bottom: 0;
  padding-left: 25px;
  font-size: 16px;
  font-weight: 600;
}
.offcanvas-header-bottom-2 h5 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 25px;
}
.fhb-container {
  width: 10%;
}
.almost-full-modal {
  width: 1400px;
}

.links {
  text-decoration: underline;
  color: #e46222;
}
.datepicker {
  overflow: hidden;
}
.file-d-input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
.file-d-input::file-selector-button {
  cursor: pointer;
}
.large-table.table tr td.align-middle {
  vertical-align: middle !important;
}
.background-dark {
  background: #1c1d23 !important;
}
.w-16 {
  width: 16.66666667% !important;
}
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-date-label .custom-date-form label:first-child {
  display: none;
}
.opacity-0 {
  opacity: 0;
}
tr.deleted-col {
  color: #f10d0d;
  background-color: #f10d0d1a;
}
